import React from 'react';
import clsx from 'clsx';
import './Text.css';

const Text = ({ children, green, classname, white, bold, greenLight }) => (
  <p
    className={clsx(`Text ${classname}`, {
      'Text--green': green,
      'Text--greenLight': greenLight,
      'Text--white': white,
      'Text--bold': bold,
    })}
  >
    {children}
  </p>
);

export default Text;
