import React from 'react';
import SectionTitle from '../SectionTitle/SectionTitle';
import Text from '../Text/Text';
import './Who.css';
import COS_LOGO from '../../images/COS_LOGO.png';

const Who = () => {
  return (
    <div className="Who">
      <div className="Who__left">
        <SectionTitle greenLight={true} classname="Who__title">
          Qui sommes nous ?
        </SectionTitle>
        <Text>
          Des officines indépendantes qui ont fait le choix de rejoindre une
          coopérative fédérée autour de valeurs communes.
        </Text>
      </div>
      <div className="Who__right">
        <img src={COS_LOGO} className="Who__logo" alt="smile" loading="lazy" />
      </div>
    </div>
  );
};

export default Who;
