import React from 'react';
import { Link } from 'react-router-dom';
import HeaderLink from './HeaderLink';
import HeaderNav from './HeaderNav';
import './Header.css';
import COS_ICON_JOIN from '../../images/COS_ICON_JOIN.png';
import COS_LOGO from '../../images/COS_LOGO.png';

const Header = () => (
  <header className="Header">
    <Link
      to="/"
      className="Header__logo-link"
      itemScope
      itemType="https://schema.org/MedicalOrganization"
    >
      <img
        src={COS_LOGO}
        className="Header__logo"
        alt=""
        itemProp="logo"
        loading="lazy"
      />
    </Link>
    <HeaderNav className="Header__nav" />
    <div className="Header__top-link">
      <HeaderLink className="Header__link-join" to="/nous-rejoindre">
        <img
          className="Header__link-logo"
          src={COS_ICON_JOIN}
          alt="logo nous rejoindre"
          loading="lazy"
        />
        Nous rejoindre
      </HeaderLink>
    </div>
  </header>
);

export default Header;
