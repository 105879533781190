import React, { useEffect } from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';

const Layout = ({ children, logo }) => {
  useEffect(() => {
    window && window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      {children}
      <Footer logo={logo} />
    </>
  );
};

export default Layout;
