import React from 'react';
import SectionTitle from '../SectionTitle/SectionTitle';
import Text from '../Text/Text';
import './Mission.css';
import COS_LOGO_ROUND from '../../images/COS_LOGO_ROUND.png';

const Mission = () => {
  return (
    <div className="Mission">
      <SectionTitle classname="Mission__title">
        Notre
        <strong className="SectionTitle__item--bold">&nbsp;mission</strong>
      </SectionTitle>
      <div className="Mission__container">
        <Text white={true}>
          Notre mission, votre santé. Ce qui anime les titulaires du réseau COS,
          c'est le bien être de leur patient.
        </Text>
        <Text white={true}>
          Nous améliorons la performance commerciale, apportons des services à
          valeur ajoutée et un accompagnement opérationnel au sein de nos
          officines. Nous préparons nos officines aux transformations
          structurelles du métier et aux nouveaux usages des patients.
        </Text>
        <img
          src={COS_LOGO_ROUND}
          className="Mission__logo"
          alt="smile"
          loading="lazy"
        />
      </div>
    </div>
  );
};

export default Mission;
