import React, { useEffect, useState, useRef } from 'react';
import clsx from 'clsx';
import { NavLink } from 'react-router-dom';
import './HeaderNav.css';
import menuV0 from '../../icons/menuV0.svg';
import exitMenu from '../../icons/Exitmenu.svg';

const HeaderNav = ({ className }) => {
  const node = useRef();

  const [open, setOpen] = useState(false);

  const toggleOpen = (e) => {
    if (!window.matchMedia('(min-width: 60em)').matches) setOpen(!open);
  };

  const handleClickOutside = (e) => {
    if (node.current.contains(e.target)) {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    if (open) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [open]);

  return (
    <nav ref={node} className={clsx('HeaderNav', { [className]: className })}>
      <button
        className="HeaderNav__burger-button"
        aria-expanded={open}
        onClick={toggleOpen}
      >
        {open ? (
          <img
            className="HeaderNav__burger-button-image"
            src={exitMenu}
            alt="close menu"
          />
        ) : (
          <img
            className="HeaderNav__burger-button-image"
            src={menuV0}
            alt="open"
          />
        )}
      </button>
      <ul className="Header-menu-list" hidden={!open} onClick={toggleOpen}>
        <li>
          <NavLink
            exact
            className="Header-menu-item"
            activeClassName="Header-menu-item--active"
            to="/"
          >
            Accueil
          </NavLink>
        </li>
        <li>
          <NavLink
            className="Header-menu-item"
            activeClassName="Header-menu-item--active"
            to="/notre-reseau"
          >
            Notre réseau
          </NavLink>
        </li>
        <li>
          <NavLink
            className="Header-menu-item"
            activeClassName="Header-menu-item--active"
            to="/nos-partenaires"
          >
            Nos partenaires
          </NavLink>
        </li>
        <li>
          <NavLink
            className="Header-menu-item"
            activeClassName="Header-menu-item--active"
            to="/nos-pharmacies"
          >
            Nos pharmacies
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default HeaderNav;
