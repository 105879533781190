import React from 'react';
import SectionTitle from '../SectionTitle/SectionTitle';
import Square from '../Square/Square';
import './Values.css';
import COS_ICON_HEALTH from '../../images/COS_ICON_HEALTH.png';
import COS_ICON_HEART from '../../images/COS_ICON_HEART.png';
import COS_ICON_PERFORMANCE from '../../images/COS_ICON_PERFORMANCE.png';
import COS_ICON_JOIN from '../../images/COS_ICON_JOIN.png';
import COS_ICON_CHAT from '../../images/COS_ICON_CHAT.png';
import COS_ICON_ROCKET from '../../images/COS_ICON_ROCKET.png';

const values = [
  {
    image: COS_ICON_HEALTH,
    label: 'Proximité',
  },
  {
    image: COS_ICON_HEART,
    label: 'Équité',
  },
  {
    image: COS_ICON_PERFORMANCE,
    label: 'Performance',
  },
  {
    image: COS_ICON_JOIN,
    label: 'Indépendance',
  },
  {
    image: COS_ICON_CHAT,
    label: 'Transparence',
  },
  {
    image: COS_ICON_ROCKET,
    label: 'Engagement',
  },
];

const Values = () => {
  return (
    <div className="Values">
      <SectionTitle classname="Values__title">
        Nos
        <strong className="SectionTitle__item--bold">
          &nbsp;valeurs : PEPITE
        </strong>
      </SectionTitle>

      <div className="Values__squares">
        {values.map((value, index) => (
          <Square
            key={index}
            label={value.label}
            image={value.image}
            className="Values__square"
          />
        ))}
      </div>
    </div>
  );
};

export default Values;
