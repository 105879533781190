import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';
import Text from '../Text/Text';
import COS_LOGO_MEDIUM from '../../images/COS_LOGO_MEDIUM.png';
import COS_FOOTER_LOGO from '../../images/COS_FOOTER_LOGO.jpg';
import VALWIN_LOGO from '../../images/VALWIN_LOGO.png';

const Footer = ({ logo }) => (
  <footer className="Footer">
    <div className="Footer__logo">
      <img src={logo ? logo : COS_FOOTER_LOGO} alt="" />
    </div>
    <div className="Footer__element">
      <Text classname="Footer__title Footer__text" white={true} bold={true}>
        Contactez-nous
      </Text>
      <Text classname="Footer__text" white={true}>
        COS
      </Text>
      <Text classname="Footer__text" white={true}>
        26 rue du faubourg Saint-Antoine
      </Text>
      <Text classname="Footer__text" white={true}>
        75012 Paris
      </Text>
    </div>
    <div className="Footer__separator" />
    <div className="Footer__element">
      <Text white={true} bold={true} classname="Footer__title Footer__text">
        Site internet
      </Text>
      <Link className="Footer__link" to="/">
        <Text classname="Footer__text" white={true}>
          Accueil
        </Text>
      </Link>
      <Link className="Footer__link" to="/notre-reseau">
        <Text classname="Footer__text" white={true}>
          Notre réseau
        </Text>
      </Link>
      <Link className="Footer__link" to="/nos-partenaires">
        <Text classname="Footer__text" white={true}>
          Nos partenaires
        </Text>
      </Link>
      <Link className="Footer__link" to="/nos-pharmacies">
        <Text classname="Footer__text" white={true}>
          Nos pharmacies
        </Text>
      </Link>
    </div>
    <div className="Footer__separator" />
    <div className="Footer__element">
      <Text white={true} bold={true} classname="Footer__title Footer__text">
        Information
      </Text>
      <Link className="Footer__link" to="/plan-du-site">
        <Text classname="Footer__text" white={true}>
          Plan du site
        </Text>
      </Link>
      <Link className="Footer__link" to="/cgu">
        <Text classname="Footer__text" white={true}>
          CGU
        </Text>
      </Link>
      <Text classname="Footer__text" white={true}>
        ©Valwin 2024
      </Text>
      <Text classname="Footer__text" white={true}>
        Mise à jour le 01/07/24
      </Text>
    </div>
    <div className="Footer__separator" />
    <div className="Footer__element">
      <div className="Footer__logos">
        <img src={COS_LOGO_MEDIUM} alt="" className="Footer__logo__cos" />
        <img src={VALWIN_LOGO} alt="" className="Footer__logo__valwin" />
      </div>
    </div>
    <div className="Footer__back" />
  </footer>
);

export default Footer;
