import React from 'react';
import clsx from 'clsx';
import './SectionTitle.css';

const SectionTitle = ({ children, green, classname, greenLight }) => (
  <h2
    className={clsx(`SectionTitle ${classname}`, {
      'SectionTitle--green': green,
      'SectionTitle--green-light': greenLight,
    })}
  >
    {children}
  </h2>
);

export default SectionTitle;
