import React from 'react';
import clsx from 'clsx';
import './Square.css';

const Square = ({ label, image, className }) => {
  return (
    <div
      className={clsx(`Square`, {
        [className]: className,
      })}
    >
      <img
        src={image}
        className="Square__img"
        alt="Icône valeur"
        loading="lazy"
      />
      <p className="Square__text"> {label}</p>
      <hr className="Square__separator" />
    </div>
  );
};

export default Square;
