import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Carousel.css';

const Carousel = ({ imagesDesktop, imagesMobile }) => {
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
  };
  return (
    <div>
      <div className="Carousel__desktop">
        <Slider {...settings}>
          {imagesDesktop.map((image, index) => (
            <a href={image.href} key={index}>
              <img src={image.src} alt="" />
            </a>
          ))}
        </Slider>
      </div>
      <div className="Carousel__mobile">
        <Slider {...settings}>
          {imagesMobile.map((image, index) => (
            <a href={image.href} key={index}>
              <img src={image.src} alt="" />
            </a>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Carousel;
